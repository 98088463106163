<template>
  <div>
    <b-container class="fullpage" fluid="xl">
          <div class="not-found">
            <div class="not-found__icon">
              <div class="not-found__icon--text">4</div>
              <div class="not-found__icon--image"></div>
              <div class="not-found__icon--text">4</div>
            </div>
            <h2>{{tr("404-title")}}</h2>
            <p>{{tr("404-desc")}}</p>
            <div class="not-found__decorator"></div>
            <div class="not-found__button button-route">
              <SimpleBtn
                type="light"
                :link="'/'">
                  {{tr("404-btn")}}
              </SimpleBtn>
            </div>
          </div>
    </b-container>
  </div>
</template>

<script>
import SimpleBtn from '@/components/decors/SimpleBtn.vue'
export default {
  components: {
        SimpleBtn
    }
}
</script>